import {
	useFormAction,
	useMatches,
	useNavigation,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { type ClassValue, clsx } from "clsx";
import { format, set } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import pick from "lodash.pick";
import { type ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useSpinDelay } from "spin-delay";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function useParentData(pathname: string): unknown {
	const matches = useMatches();
	const parentMatch = matches.find((match) => match.pathname === pathname);
	if (!parentMatch) return null;
	return parentMatch.data;
}

export function getSearchParams<T extends string>(
	request: Request,
	params: T[],
): { [K in T]: string | null } {
	const url = new URL(request.url);
	const searchParams: { [K in T]?: string | null } = {};

	for (const param of params) {
		searchParams[param] = url.searchParams.get(param);
	}

	return searchParams as { [x in T]: string | null };
}

export function getInitials(fullName: string | null | undefined): string {
	if (!fullName) return "";
	const nameParts = fullName.trim().split(/\s+/);
	if (nameParts.length === 1) {
		// If there's only one word, return its first letter
		return nameParts[0][0];
	}
	// Otherwise, return the first letter of the first word and the first letter of the last word
	return (
		nameParts[0][0].toUpperCase() +
		nameParts[nameParts.length - 1][0].toUpperCase()
	);
}

export function formatCurrency(value: string | number | null | undefined) {
	if (!value) return "0";
	if (typeof value === "string") {
		value = Number(value.replaceAll(".", ""));
	}
	return new Intl.NumberFormat("id-ID").format(value);
}

export function formatDate(
	date: Date | string | null | undefined,
	locale?: string,
) {
	if (!date) return "-";
	return new Date(date).toLocaleString(locale ?? "id", {
		dateStyle: "long",
	});
}

//FIXME
export function changeTimeToMidnight(date?: Date) {
	if (!date) return null;

	const formatted = format(date, "yyyy-MM-dd");

	const zonedTime = fromZonedTime(formatted, "Asia/Jakarta");

	return zonedTime;
}

//FIXME
export function changeTimeTo2359(date?: Date | string) {
	if (!date) return null;

	const zonedTime = fromZonedTime(date, "Asia/Jakarta");

	return set(zonedTime, {
		hours: 23,
		minutes: 59,
		seconds: 59,
		milliseconds: 999,
	});
}

export function getLastNameAndRest(fullname: string) {
	const fullName = fullname.trim();

	const words = fullName.split(" ");

	if (words.length === 1) {
		return {
			firstName: fullName,
			lastName: fullName,
		};
	}

	const firstName = words.slice(0, words.length - 1).join(" ");
	const lastName = words[words.length - 1];

	return {
		firstName: firstName,
		lastName: lastName,
	};
}

export function capitalize(str: string) {
	return str.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get("X-Forwarded-Host") ??
		request.headers.get("host") ??
		new URL(request.url).host;
	const protocol = host.includes("localhost") ? "http" : "https";
	return `${protocol}://${host}`;
}

export function getReferrerRoute(request: Request) {
	// spelling errors and whatever makes this annoyingly inconsistent
	// in my own testing, `referer` returned the right value, but 🤷‍♂️
	const referrer =
		request.headers.get("referer") ??
		request.headers.get("referrer") ??
		request.referrer;
	const domain = getDomainUrl(request);
	if (referrer?.startsWith(domain)) {
		return referrer.slice(domain.length);
	}
	return "/";
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
	...headers: Array<ResponseInit["headers"] | null | undefined>
) {
	const merged = new Headers();
	for (const header of headers) {
		if (!header) continue;
		for (const [key, value] of new Headers(header).entries()) {
			merged.set(key, value);
		}
	}
	return merged;
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit["headers"] | null | undefined>
) {
	const combined = new Headers();
	for (const header of headers) {
		if (!header) continue;
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value);
		}
	}
	return combined;
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
	...responseInits: Array<ResponseInit | null | undefined>
) {
	let combined: ResponseInit = {};
	for (const responseInit of responseInits) {
		combined = {
			...responseInit,
			headers: combineHeaders(combined.headers, responseInit?.headers),
		};
	}
	return combined;
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = "POST",
	state = "non-idle",
}: {
	formAction?: string;
	formMethod?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
	state?: "submitting" | "loading" | "non-idle";
} = {}) {
	const contextualFormAction = useFormAction();
	const navigation = useNavigation();
	const isPendingState =
		state === "non-idle"
			? navigation.state !== "idle"
			: navigation.state === state;
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	);
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
	formAction,
	formMethod,
	delay = 400,
	minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
	Parameters<typeof useSpinDelay>[1] = {}) {
	const isPending = useIsPending({ formAction, formMethod });
	const delayedIsPending = useSpinDelay(isPending, {
		delay,
		minDuration,
	});
	return delayedIsPending;
}

function callAll<Args extends Array<unknown>>(
	...fns: Array<((...args: Args) => unknown) | undefined>
) {
	return (...args: Args) => {
		for (const fn of fns) fn?.(...args);
	};
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = useState(false);

	function getButtonProps(
		props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
	) {
		const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>["onBlur"] =
			() => setDoubleCheck(false);

		const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>["onClick"] =
			doubleCheck
				? (e) => {
						setDoubleCheck(false);
					}
				: (e) => {
						e.preventDefault();
						setDoubleCheck(true);
					};

		const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>["onKeyUp"] = (
			e,
		) => {
			if (e.key === "Escape") {
				setDoubleCheck(false);
			}
		};

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
			onKeyUp: callAll(onKeyUp, props?.onKeyUp),
		};
	}

	return { doubleCheck, getButtonProps };
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
	fn: Callback,
	delay: number,
) {
	let timer: ReturnType<typeof setTimeout> | null = null;
	return (...args: Parameters<Callback>) => {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			fn(...args);
		}, delay);
	};
}

/**
 * Debounce a callback function
 */
export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = useRef(callback);
	useEffect(() => {
		callbackRef.current = callback;
	});
	return useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay,
			),
		[delay],
	);
}

export async function downloadFile(url: string, retries = 0) {
	const MAX_RETRIES = 3;
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`Failed to fetch image with status ${response.status}`);
		}
		const contentType = response.headers.get("content-type") ?? "image/jpg";
		const blob = Buffer.from(await response.arrayBuffer());
		return { contentType, blob };
	} catch (e) {
		if (retries > MAX_RETRIES) throw e;
		return downloadFile(url, retries + 1);
	}
}

export const generateRandomString = () => {
	return Math.floor(Math.random() * Date.now()).toString(36);
};

export function typedPick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
	return pick(obj, keys) as Pick<T, K>;
}

export function useCapturedRouteError() {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	return error;
}

export function getImageData(event: ChangeEvent<HTMLInputElement>) {
	const dataTransfer = new DataTransfer();

	if (!event.target.files) return { files: "", displayUrl: "" };

	for (const image of Array.from(event.target.files)) {
		dataTransfer.items.add(image);
	}

	const files = dataTransfer.files;
	const displayUrl = URL.createObjectURL(event.target.files[0]);

	return { files, displayUrl };
}

export function getGradeName(t: any, grade: number | undefined) {
	if (grade === undefined) return "-";
	if (grade === -2) return t("playgroup");
	if (grade === -1) return t("pre-kindergarten");
	if (grade === 0) return t("kindergarten");
	if (grade >= 1 && grade <= 12) return t(`grade_${grade}`);
}

export function findNearestDate(dates: (Date | null)[]): Date | null {
	const now = new Date();
	const validDates = dates.filter((date): date is Date => date !== null);

	if (validDates.length === 0) return null;

	return validDates.reduce((nearest, current) => {
		const diffNearest = Math.abs(now.getTime() - nearest.getTime());
		const diffCurrent = Math.abs(now.getTime() - current.getTime());
		return diffCurrent < diffNearest ? current : nearest;
	});
}
